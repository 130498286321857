function rpc (trp, api, ctx) {
    let nextReqId = 0;
    const callbacks = [];

    ctx = ctx || { };
    ctx.onmessage = dispatch;
    const promise = (resolve, reject, reqId) => {
        callbacks[reqId] = [resolve, reject];
        if (ctx.timeout)
            setTimeout(() => {
                delete callbacks[reqId];
                reject('timeout');
            }, ctx.timeout);
    };
    ctx.call = function(method) {
        if (trp.isReady && !trp.isReady())
            return Promise.reject(new Error("rpc transport not ready"));

        let req = {
            reqId: nextReqId++,
            method: method,
            args: Array.prototype.slice.call(arguments, 1)
        };
        //console.log('req', req);
        trp.send(req);

        return new Promise((resolve, reject) => promise(resolve, reject, req.reqId));
    };
    ctx.call2 = function(method) {
        if (trp.isReady && !trp.isReady())
            return Promise.reject(new Error("rpc transport not ready"));

        let req = {
            reqId: nextReqId++,
            method: method,
            args: Array.prototype.slice.call(arguments, 1, -1),
        };
        //console.log('req', req);
        trp.send(req, arguments[arguments.length - 1]);

        return new Promise((resolve, reject) => promise(resolve, reject, req.reqId));
    };

    function dispatch(obj) {
        //console.log(obj);
        if ("result" in obj || "err" in obj) {
            const { result, err, reqId } = obj;
            if (reqId in callbacks) {
                const [succeed, fail] = callbacks[reqId];
                delete callbacks[reqId];
                if (err)
                    fail(err);
                else
                    succeed(result);
            } else {
                console.log("unexpected reqId", reqId);
            }
        } else {
            const { method, reqId } = obj;
            if (method) {
                function sendError(err) {
                    console.log(err);
                    let resp = {
                        reqId: reqId,
                        err: String(err)
                    };
                    //console.log('responding', resp);
                    if (!trp.isReady || trp.isReady())
                        trp.send(resp);
                    else
                        console.log("rpc transport not ready");
                }
                function sendResult(res) {
                    try {
                        let result = res;
                        let transfer;
                        try {
                            // detect strict formatting { result: <result>, transfer: <array of buffers> } to transfer buffer
                            if (Object.keys(res).length == 2 && res.result && res.transfer && Array.isArray(res.transfer)) {
                                result = res.result;
                                transfer = res.transfer;
                            }
                        } catch (e) {
                        }
                        let resp = {
                            reqId: reqId,
                            result: typeof result === "undefined"? null : result
                        };
                        //console.log('responding', resp);
                        if (!trp.isReady || trp.isReady())
                            trp.send(resp, transfer);
                        else
                            console.log("rpc transport not ready");
                    } catch(err) {
                        // sometimes JSON.stringify fails ... (eg. self referencing result)
                        console.log(err);
                        console.log("method", method, "result was", result);
                        sendError(err);
                    }
                }
                try {
                    const f = api[method];
                    if (!f)
                        throw new Error("unknown api call " + method);
                    const result = f.apply(ctx, obj.args);

                    if (result && result.then)
                        result.then(sendResult, sendError);
                    else
                        sendResult(result);
                } catch(err) {
                    sendError(err);
                }
            } else {
                console.log("malformed message", obj);
            }
        }
    }

    return ctx;
}

export function wsrpc (ws, api, ctx) {
    const trp = {
        isReady: () => { return ws.readyState == ws.OPEN },
        send: data => ws.send(JSON.stringify(data)),
    };
    ctx = rpc(trp, api, ctx);
    ws.onmessage = (msg) => {
        try {
            ctx.onmessage(JSON.parse(msg.data? msg.data : msg.toString()));
        } catch (e) {
            console.log("non json msg", e);
        }
    };
    return ctx;
}

export function workerrpc(worker, api, ctx) {
    const trp = {
        send: (data, transfer) => worker.postMessage(data, transfer),
    };
    ctx = rpc(trp, api, ctx);
    ctx.worker = worker;
    worker.onmessage = msg => ctx.onmessage(msg.data);
    return ctx;
}

export function createWebSocket(url) {
    console.log(url);
    url = url.split("/");
    const host = url[2];
    url[0] = url[0].replace("http", "ws");
    const isSsl = url[0] == "wss:";
    url = url.join("/");
    const ws = new WebSocket(url);

    /* hack to help emscripten deduce address and port, as their parsing is very limited */
    // TODO report bug to emscripten
    const { addr, port } = host.split(":", 2);
    ws._socket = {
        remoteAddress: addr,
        remotePort: port? port : isSsl? 443 : 80
    };

    return ws;
}
